.puzzle{
    width: calc(100% - 30px);
    height: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
    padding-bottom: 30px;
    padding-top: 20px;
    flex: 1 1;
}

.play-btn,.play-btn-alt{
    border-radius: 7px;
    margin-bottom: 20px;
    padding: 20px 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    word-spacing: 2px;
    cursor: pointer;
    transition: .25s;
}

.play-btn{
    background-color: #FFF;
    border: 1px solid #FFF;
    color: #111;
}
.play-btn:hover{
    background-color: white;
    color: #222;
}

.play-btn-alt{
    background-color: #111;
    border: 1px solid #222;
    color: #FFF;
}
.play-btn-alt:hover{
    background-color: #111;
    border: 1px solid #222;
    color: #FFF;
}

.word-box {
    margin: 25px auto;
    width: 100%;
    padding: 15px;
    text-align: center;
    color: #FFF;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 2px;
    height: 65px;
    border-bottom: 3px solid #FFF;
}

.success-box {
    margin: auto;
    width: 100%;
    padding: 25px 15px;
    text-align: center;
    background-color: var(--main-color);
    color: white;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 1px;
    border-radius: 7px;
    margin-bottom: 30px;
}

.progress {
    position: absolute;
    top: 34px;
    left: 12px;
    width: calc(100% - 24px);
    border-radius: 7px; 
    height: 5px;
}

.progress::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 7px;
}

.progress::-webkit-progress-value {
    background-color: var(--main-color);
    border-radius: 7px;
}

.progress::-moz-progress-bar {
    background-color: transparent;
    border-radius: 7px;
}

.progress::-moz-progress-value {
    background-color: var(--main-color);
    border-radius: 7px;
}

.error-box {
    opacity: 0;
    width: 100%;
    margin: 15px 0px;
    text-align: center;
}

.error-box.show {
    animation: opac 2s reverse;
}

@keyframes opac {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.error-box span {
    font-size: 12px;
    background-color: #FFF;
    padding: 6px 10px;
    color: #111;
    border-radius: 3px;
}

.letter-box {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Added this line to enable wrapping */
}

.letter-box .letter-cell {
    margin: 5px;
    width: 35px;
    height: 45px;
    border-radius: 5px;
    font-size: 24px;
    /* font-size: 1.5rem; */
    background-color: #111;
    color: #FFF;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.letter-box .letter-cell:disabled {
    opacity: 0.5;
}

.letter-box .letter-cell:last-child {
    width: 70%;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 16px;
}

.action-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px auto 0px auto;
}

.action-btns button {
    border-radius: 5px;
    padding: 7px 25px;
    background-color: white;
    font-weight: 500;
    border: 1px solid #e1e1e1;
    font-size: 14px;
    color: #555;
}

.action-btns button:disabled {
    opacity: .5;
}

.action-btns .btn-reset {
    margin: 10px 15px;
    height: 20px;
    width: 20px;
}


/** Leaderboard */
.leaderboard{
    z-index: 55;
}

.leaderboard-sm > div{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #FFF;
    padding: 10px;
    border-radius: 7px;
}

.leaderboard-sm > div.highlight{
    transform: scale(1.04);
    font-size: 16px;
    background: #FFF;
    color: #111;
    padding: 15px 8px;
    font-weight: 900;
    box-shadow: 0rem .15rem 2rem rgba(255,255,255,.95);
}