.race-lights {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 79px;
  }
  
  .light {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 5px;
    background-color: #111; /* Initial background color is slightly visible */
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5); /* Box shadow for realism */
  }
  
  .off {
    background-color: #000; /* Off state color */
  }
  
  .red {
    background-color: red; /* Red light state */
  }
  
  .green {
    background-color: green; /* Green light state */
  }
  