
/** Leaderboard */
.leaderboard{
}
.leaderboard > div{
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    color: #FFF;
    padding: 10px;
    border: 1px solid #222;
    border-radius: 7px;
    margin-top: 7px;
}
.leaderboard > div.highlight{
    transform: scale(1.04);
    font-size: 16px;
    background: #FFF;
    color: #111;
    padding: 15px 8px;
    font-weight: 900;
    box-shadow: 0rem .15rem 2rem rgba(255,255,255,.95);
}